import styled from 'styled-components'

export const Container = styled.article`
  display: flex;
  flex-direction: column;
  margin: 2rem 0 7rem;
`

type TextProps = {
  bold?: boolean
  italic?: boolean
  underline?: boolean
  centered?: boolean
}

export const Text = styled.p<TextProps>`
  font-size: 1.125rem;
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
  font-style: ${({ italic }) => (italic ? 'italic' : 'normal')};
  text-align: ${({ centered }) => (centered ? 'center' : 'start')};
  text-decoration: ${({ underline }) => (underline ? 'underline' : 'none')};
  line-height: 1.5;
  margin: 0 0 0.75rem;
`

export const ListItemTitle = styled.span`
  font-style: italic;
  font-weight: bold;
`

export const BrowserLinks = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 1.125rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
`

export const CookieTable = styled.table`
  width: 100%;
  font-size: 1.125rem;
  border: 1px solid #ddd;
  border-spacing: 0;
  margin-bottom: 1rem;

  td,
  th {
    border: 1px solid #ddd;
    text-align: left;
    padding: 8px;
  }

  th {
    text-decoration: underline;
  }
`
