import * as React from 'react'
import * as S from './PrivacyPolicySectionAtoms'
import { BrowserCookieLinks } from '../../constants/browser'

const PrivacyPolicy: React.FC = () => (
  <S.Container>
    <S.Text bold centered>
      PRIVACY POLICY
    </S.Text>

    <S.Text italic centered>
      being in force from 10.10.2021.
    </S.Text>

    <S.Text>
      The present Privacy Policy was drafted to lay down the rules concerning the using of cookies
      which occur while using our Power Retro Applications (hereinafter referred to as:{' '}
      <strong>Application</strong>) including our website (
      <a href="https://powerretro.io">https://powerretro.io</a>), hereinafter referred to as:{' '}
      <strong>Website</strong>) and cloud services and the downloadable products including
      downloadable software products and the web application operated by Tappointment Korlátolt
      Felelősségű Társaság (company registration number: 07-09-025091; seat: 8000 Székesfehérvár,
      Király sor 30., phone number: +36 1 611 9786, e-mail: hello (at) tappointment.com, hereinafter
      referred to as: <strong>Company</strong>).
    </S.Text>

    <S.Text>
      When using the Application, we do not process any personal data, but nevertheless we do use
      cookies.
    </S.Text>

    <S.Text>
      The purpose of the present Privacy Policy is to <strong>provide the users</strong> (the person
      who uses the Application, hereinafter referred to as: <strong>User</strong>){' '}
      <strong>
        with information about the cookies performed on the Application of the Company, which is
        linked to the operation of the Application.
      </strong>{' '}
      The present Privacy Policy applies to all Application including the entire Website and all its
      subdomains too. The present Privacy Policy does not apply to websites accessible on domains
      other than the Website, regardless of the fact if the User may have accessed them via a link
      placed on the Website or on the other Application. In connection with the cookies on the
      Application, the Company acts in accordance with the legislation on data protection and
      advertising being in force - in particular in accordance with the provisions of the Act CXII
      of 2011 on the right to informational self-determination and on the freedom of information
      (hereinafter referred to as: <strong>Infotv</strong>.); and that of the regulation (EU)
      2016/679 of the European Parliament and of the Council on the protection of natural persons
      with regard to the processing of personal data and on the free movement of such data, and
      repealing Directive 95/46/EC (hereinafter referred to as: <strong>GDPR</strong>) and the Act C
      of 2003 on electronic communications.
    </S.Text>

    <S.Text>
      In the following, general information about the cookies used on the Application is laid down.
    </S.Text>

    <S.Text bold underline>
      Downloadable Product
    </S.Text>

    <S.Text>
      When using the Application, the User can download products (e.g.: pdf files), in which
      personal data may arise, including solely the User’s profile picture and name. These data are
      not processed by the Company, but by the operator of the Atlassian Marketplace (Atlassian
      Corporation PLC; seat: Level 6, 341 George Street, Sydney, NSW, 2000 Australia). This company
      is also obliged to act in accordance with the provisions of the relevant legislation, in
      particular with that of the GDPR.
    </S.Text>

    <S.Text bold underline>
      Cookies
    </S.Text>

    <S.Text>
      Cookies are short data files placed on the User's computer (or on other devices, such as on a
      mobile phone) by the Application being visited. The purpose of the cookie is to facilitate the
      given infocommunication and internet service, to make it more convenient, and to provide the
      owner of the Application with information. There are many varieties of cookies, but they can
      generally be classified into two major groups. One is the temporary cookie which the
      Application only places on a User's device during a specific session (e.g. during security
      authentication), the other is the persistent cookie (e.g.: recording permanent settings) which
      stays on the device for a longer period of time (this also depends on the settings of the
      device or that of the browser).
    </S.Text>

    <S.Text>
      Company uses cookies for two purposes. One type of cookies includes those which are essential
      for the performance of the service provided by Company and as for the operation and use of the
      Application. The other type of cookies includes those which are not essential for the above,
      but which monitor and evaluate the effectiveness and efficiency of individual pages and
      subpages and which inform Company about the behaviour of the Users on the Application (which
      information Company intends to use for the development of the Application and for market
      research purposes). Please note that there are cookies in both categories which handle
      personal information. On the Application, Company uses both cookies which handle personal data
      and cookies which do not handle personal data. The scope of data being processed primarily
      affects the IP address of the User, his or her activity on the Application (clicks, downloads,
      etc.), his or her location, the type of his or her browser and operating system. However, the
      Company is not able to identify the User from these data collected on the Application, it does
      not have a record of these data and cannot target specific data. Consequently, these data do
      not identify the User to the Company and is therefore not personal data.
    </S.Text>

    <S.Text>Cookies are stored until the period set forth in the table set below.</S.Text>

    <S.Text>
      In addition to the functions and cookies which are essential for the operation of the
      Application and thus for providing the service of Company and for assuring the use of the
      Application by the User (Point b) (1) Article 6 of GDPR), Company only uses cookies, if the
      User has given his or her consent to their use (Point a) (1) Article 6 of GDPR). When
      accessing the Application for the first time and at any time thereafter, the User can change
      his or her consent given based on the present Privacy Policy by clicking on the "Cookie Accept
      or Decline" button on the <strong>Website</strong>, where will be a direct link to the present
      Privacy Policy. User can disable our collection of analytics information from{' '}
      <strong>Downloadable Products</strong> via the Administrator settings or by blocking
      collection at the local network level.
    </S.Text>

    <S.Text>
      It is important to note that the Company does not remember any identifier or password when
      cookies are enabled. The User can use the electronic services of the Company in complete
      safety even if cookies are accepted.
    </S.Text>

    <S.Text>
      Browsers offer the possibility to change cookie settings in general. Most browsers
      automatically accept cookies as default cookies, but this can be changed in order to prevent
      their automatic acceptance after the change of settings. More information about the settings
      of some popular browsers are available at the following links:
    </S.Text>

    <S.BrowserLinks>
      {Object.entries(BrowserCookieLinks).map(([name, link]) => (
        <a key={name} href={link} target="_blank">
          {name}
        </a>
      ))}
    </S.BrowserLinks>

    <S.Text bold underline>
      Detailed list of the cookies used
    </S.Text>

    <S.CookieTable>
      <thead>
        <tr>
          <th>Cookie name</th>
          <th>Purpose</th>
          <th>Type</th>
          <th>Period</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>_ga</td>
          <td>
            The Google Analytics uses it, which records a unique identifier, to generate statistical
            data about the Users use of the Application.
          </td>
          <td>Persistent. Statistical cookie.</td>
          <td>2 years.</td>
        </tr>
        <tr>
          <td>_gat</td>
          <td>The Google Analytics uses it, to control the frequency of queries.</td>
          <td>Persistent. Statistical cookie.</td>
          <td>1 day.</td>
        </tr>
        <tr>
          <td>_gid</td>
          <td>
            The Google Analytics uses it, which records a unique identifier, to generate statistical
            data about the Users use of the Application.
          </td>
          <td>Persistent. Statistical cookie.</td>
          <td>1 day.</td>
        </tr>
        <tr>
          <td>collect</td>
          <td>
            Sends data to Google Analytics about the User’s device and behaviour. Tracks the User
            across devices and marketing channels.
          </td>
          <td>Temporary. Statistical cookie.</td>
          <td>Until the end of the session.</td>
        </tr>
        <tr>
          <td>tk_ai</td>
          <td>
            It records what the Users view on the Application. The data helps to optimise the
            Application.
          </td>
          <td>Persistent. Statistical cookie.</td>
          <td>5 years.</td>
        </tr>
        <tr>
          <td>tk_qs</td>
          <td>
            It records what the Users view on the Application. The data helps to optimise the
            Application.
          </td>
          <td>Persistent. Statistical cookie.</td>
          <td>1 day.</td>
        </tr>
        <tr>
          <td>tk_lr</td>
          <td>
            Collects data on the behaviour of the Users to the Application. It aims to focus on
            relevant content that matches the interests of the Users.
          </td>
          <td>Persistent. Marketing cookie.</td>
          <td>1 year.</td>
        </tr>
        <tr>
          <td>tk_or</td>
          <td>The Google Analytics uses it, to control the frequency of queries.</td>
          <td>Persistent. Marketing cookie.</td>
          <td>5 years.</td>
        </tr>
        <tr>
          <td>tk_r3d</td>
          <td>
            Collects data on the behaviour of the Users to the Application. It aims to focus on
            relevant content that matches the interests of the Users.
          </td>
          <td>Persistent. Marketing cookie.</td>
          <td>2 days.</td>
        </tr>
        <tr>
          <td>tk_tc</td>
          <td>
            Collects data on the behaviour of the Users to the Application. It aims to focus on
            relevant content that matches the interests of the Users.
          </td>
          <td>Temporary. Marketing cookie.</td>
          <td>Until the end of the session.</td>
        </tr>
      </tbody>
    </S.CookieTable>

    <S.Text>
      The statistical cookies do not collect any personal data. The Company uses statistical cookies
      to collect information about how the Users use the Application. The anonymously collected data
      is used to improve the Application and the User experience.
    </S.Text>
    <S.Text>
      The marketing cookies track the activity of the Users to the Application. They are used to
      serve meaningful advertisements to individual Users and to encourage activity.
    </S.Text>
    <S.Text>
      The Website is hosted by Amazon Web Services, Inc. (address: 410 Terry Avenue North, Seattle,
      WA, United States), this company provides webhosting services to the Company.
    </S.Text>
    <S.Text>
      The Company is using Firebase (address: San Francisco, California, United States) to develop
      iOS, Android and Web apps for the Application, which provides tools for tracking analytics,
      reporting and fixing app crashes.
    </S.Text>
    <S.Text>
      These companies are also obliged to act in accordance with the provisions of the relevant
      legislation, in particular with that of the GDPR. In the course of this, these companies do
      not access personal data related to the use of the Application.
    </S.Text>

    <S.Text bold underline>
      Generative AI Tools
    </S.Text>

    <S.Text>
      <strong>User Consent: </strong>Our Apps do not send data to any third-party APIs by default.
      Users are provided with the choice to opt-in and utilize the generative AI tools during their
      use of the Application. By switching ON the Allow AI Assistance button, users provide their
      express consent for us to collect and process data as outlined in this Privacy Policy.
    </S.Text>
    <S.Text>
      <strong>Information Collected by AI: </strong>When users opt in for the generative AI tools
      within the Application, the 3rd party AI APIs may collect the following types of information:
    </S.Text>
    <ul style={{ marginTop: '0' }}>
      <li>
        <S.Text>
          <S.ListItemTitle>Retrospective Data: </S.ListItemTitle>
          The information provided by users during the retrospective sessions, such as retro cards,
          action items, and the summary of the retrospective.
        </S.Text>
      </li>
      <li>
        <S.Text>
          <S.ListItemTitle>Anonymous Data: </S.ListItemTitle>
          The generative AI tools may also collect and analyze anonymous, aggregated data from
          multiple users' retrospective sessions. This anonymous data will be used to improve the
          performance and functionality of the generative AI tools but will not be linked to any
          individual client's personal information.
        </S.Text>
      </li>
      <li>
        <S.Text>
          <S.ListItemTitle>User-Generated Content: </S.ListItemTitle>
          The information collected through the generative AI tools will be used solely for the
          purpose of providing the requested generative AI functionalities.
        </S.Text>
      </li>
    </ul>
    <S.Text>
      <strong>Service Improvement: </strong>We may also use the user-generated content, in an
      aggregated and anonymized form, to improve the functionality, performance, and quality of the
      generative AI tool within the Application.
    </S.Text>
    <S.Text>
      <strong>Data Retention: </strong>We will retain user generated content collected through the
      generative AI tools for as long as necessary to fulfill the purposes of given features
      connected to AI, taking into account this Privacy Policy. Once the information is no longer
      required, we will take reasonable measures to securely delete or anonymize it.
    </S.Text>
    <S.Text>
      <strong>AI tools used: </strong>Power Retro uses chatGPT 3.5 API to enhance users’ experience
      within our tool.
    </S.Text>
    <S.Text>
      <strong>Data usage not covered in this policy: </strong>For more details regarding AI data
      policies not covered in this section please refer to the chatGPT{' '}
      <a href="https://beta.openai.com/docs/usage-policies">OpenAI usage policy</a>.
    </S.Text>

    <S.Text bold underline>
      Contact Details
    </S.Text>

    <S.Text>
      Any questions or requests related to our cookie usage should be sent to the hello (at)
      tappointment.com e-mail address, or in writing to the address of Tappointment Kft, Királysor
      30., Székesfehérvár, 8000 Hungary or contact us by phone at (+36 1 611 9786) Please note that
      – in your own interest –we are only able to provide information or take any action if you have
      credibly proven your identity.
    </S.Text>
  </S.Container>
)

export default PrivacyPolicy
