/**
 * Links for cookie settings page in different browsers.
 */
export const BrowserCookieLinks = Object.freeze({
  'Google Chrome': 'https://support.google.com/accounts/answer/61416?hl=hu',
  'Firefox': 'https://support.mozilla.org/hu/kb/S%C3%BCtik%20kezel%C3%A9se',
  'Microsoft Internet Explorer 11': 'http://windows.microsoft.com/hu-hu/internet-explorer/delete-manage-cookies#ie=ie-11',
  'Microsoft Internet Explorer 10': 'http://windows.microsoft.com/hu-hu/internet-explorer/delete-manage-cookies#ie=ie-10',
  'Microsoft Edge': 'http://windows.microsoft.com/hu-hu/windows-10/edge-privacy-faq'
})
